<template>
<v-app>
    <v-app-bar
        app
        absolute
        height="100"
        class="bg-primary"
        flat>
        <div style="margin:10px;font-size:18px;font-weight:bold;color:white">
        <v-img :src="require('@/assets/logo5.png')" width="200" /></div>
        <v-spacer></v-spacer>
        <div style="cursor: pointer;margin:10px;font-size:14px;font-weight:bold;color:white" @click="$router.push({ name: '로그인' })">로그인</div>
        <div style="cursor: pointer;margin:10px;font-size:14px;font-weight:bold;color:white" @click="$router.push({ name: '회원가입' })">회원가입</div>
        <div style="cursor: pointer;margin:10px;font-size:14px;font-weight:bold;color:white">고객센터</div>
    </v-app-bar>

    <v-main app class="auth-pages">
        <fade-transition
            :duration="200"
            origin="center top"
            mode="out-in">
            <!-- your content here -->
            <v-container class="pt-6">
                <router-view></router-view>
                <!--content-footer auth v-if="!$route.meta.hideFooter"></content-footer-->
            </v-container>
        </fade-transition>
    </v-main>
    
    <div style="height: 200px">
    </div>
    <div class="py-4 px-8" style="background-color:#e9ecef">
        <div class="text-sm text-body">상호명 : 주식회사 플라이닥터</div>
        <div class="text-sm text-body">사업자등록번호 : 188-81-02379</div>
        <div class="text-sm text-body">대표자명 : 이동진</div>
        <div class="text-sm text-body">사업장주소지 : 서울특별시 강남구 테헤란로 521, 29층(삼성동, 파르나스타워)</div>
        <div class="text-sm text-body">전화번호 : 1533-3965</div>
        <div class="text-sm text-body">통신판매업번호 : 2022-서울강남-04256</div>
    </div>
</v-app>
</template>

<script>
import {
    FadeTransition
} from "vue2-transitions";
export default {
    name: "page-layout",
    components: {
        FadeTransition,
    },
    data() {
        return {
            paragraphs: "",
            tab: null,
            sheet: false,
            uid: "",
            password: "",
            bottomSheetMessage: "로그인 권한이 없습니다.",
        };
    },
    methods: {
        headerTitle() {
            switch (this.$route.name) {
                case "SignUpBasic":
                    this.paragraphs =
                        "Use these awesome forms to login or create new account in your project for free.";
                    return "Welcome!";
                default:
                    break;
            }
        }
    },
};
</script>
