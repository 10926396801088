<template>
<v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat>
    <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="d-flex align-center">
            <div>
                <v-breadcrumbs class="pb-0 px-0">
                    <v-breadcrumbs-item
                        to="/pages/dashboard/dashboard"
                        active-class="active-breadcrumb"
                        class="opacity-5 text-dark">
                        <v-icon style="margin-bottom:2px">fas fa-home</v-icon>
                    </v-breadcrumbs-item>
                    <li v-if="$route.name!='홈화면'" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item class="opacity-5 text-dark">
                        {{ $route.meta.groupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.name!='홈화면'" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item v-if="$route.meta.secondGroupName!=null" class="opacity-5 text-dark">
                        {{ $route.meta.secondGroupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.meta.secondGroupName!=null" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                    <v-breadcrumbs-item v-if="$route.meta.thirdGroupName!=null" class="opacity-5 text-dark">
                        {{ $route.meta.thirdGroupName }}
                    </v-breadcrumbs-item>
                    <li v-if="$route.meta.thirdGroupName!=null" class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
                </v-breadcrumbs>

                <h6 class="text-h6 font-weight-bolder text-typo mb-0">
                    {{ $route.name }}
                </h6>
            </div>
            <!--div
                class="drawer-toggler pa-5 ms-6 cursor-pointer"
                :class="{ active: togglerActive }"
                @click="minifyDrawer"
                v-if="!$vuetify.breakpoint.xs">
                <div class="drawer-toggler-inner">
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                    <i class="drawer-toggler-line bg-body"></i>
                </div>
            </div-->
        </v-col>

        <v-col cols="12" sm="9">
            <div class="d-flex align-center justify-end" :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'">
                <div class="text-right text-sm text-body me-4 mt-1" v-if="!$vuetify.breakpoint.xs">
                    {{pharmacy.accountUserName}}({{ pharmacy.id }})님, 환영합니다!
                </div>
                <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                    font-weight-600
                    text-capitalize
                    drawer-toggler
                    py-3
                    px-0
                    rounded-sm
                    mx-0
                    active
                    btn-dark-toggler-hover
                "
                    v-if="$vuetify.breakpoint.xs"
                    color="transparent"
                    @click="toggleDrawer">
                    <div class="drawer-toggler-inner">
                        <i class="drawer-toggler-line text-body"></i>
                        <i class="drawer-toggler-line text-body"></i>
                        <i class="drawer-toggler-line text-body"></i>
                    </div>
                </v-btn>
                <div class="me-4">
                    <v-icon
                        size="16"
                        style="cursor:pointer"
                        class="text-sm"
                        @click="$router.push({name: '마이페이지'})">fa fa-cog</v-icon>
                </div>
                <div class="text-sm me-2">
                    <v-badge
                        dot
                        :value="qnaNew"
                        color="red"
                        overlap
                        class="pe-2">
                        <v-icon
                            size="16"
                            style="cursor:pointer"
                            @click="redirectToUrl('/pages/dashboard/customer-services-temp?screen=newQuestion')">fa fa-question-circle</v-icon>
                    </v-badge>
                </div>
                <div class="text-sm me-2">
                    <v-badge
                        dot
                        :value="noticeNew"
                        color="red"
                        overlap
                        class="pe-2">
                        <v-icon
                            size="16"
                            style="cursor:pointer"
                            @click="redirectToUrl('/pages/dashboard/customer-services-temp?screen=notice')">mdi-bullhorn</v-icon>
                    </v-badge>
                </div>

                <div
                    class="text-sm d-flex justify-start align-center font-weight-600 text-capitalize"
                    style="cursor:pointer"
                    @click="logout">
                    <v-icon size="16" class="me-2">fa fa-sign-out-alt</v-icon>
                    <span class="d-sm-inline font-weight-bold d-none text-body">로그아웃</span>
                </div>

                <div class="ms-3 pb-2" style="font-size:18px;font-weight:bold;color:white" v-if="!$vuetify.breakpoint.xs">
                    <v-img :src="require('@/assets/logo6.png')" width="100" />
                </div>
            </div>
        </v-col>
    </v-row>

    <v-dialog v-model="newWorkDialog" width="300">
        <v-card class="pa-4">
            <v-card-title class="d-flex justify-center">
                <v-icon x-large class="mb-1">fas fa-clipboard-list</v-icon>
            </v-card-title>
            <v-card-text class="d-flex justify-center">
                <span class="text-body text-sm mb-1">
                    미확인 처방전을 확인해주세요!
                </span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center">
                <v-btn
                    @click="newWorkDialog=false;$router.push({name:'접수정보보기', query: {id: newItem.id}})"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      ">확인하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="adminNotificationDialog" width="400">
        <v-card class="pa-4">
            <v-card-title class="d-flex justify-center">
                <v-icon x-large class="mb-1">fas fa-check</v-icon>
            </v-card-title>
            <v-card-text class="text-center">
                <div class="text-body text-sm font-weight-bold">
                    {{adminNotificationTitle}}
                </div>
                <div class="text-body text-sm mb-1">
                    {{adminNotificationMessage}}
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center">
                <v-btn
                    v-if="adminNotificationLink.length > 0"
                    @click="adminNotificationDialog=false;goLink(adminNotificationLink)"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                        me-2
                      ">확인하기</v-btn>
                <v-btn
                    @click="adminNotificationDialog=false"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-default
                        bg-gradient-secondary
                        py-3
                        px-6
                      ">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-app-bar>
</template>

<script>
import {
    query
} from 'quill';
import '../plugins/firebase';
import firebase from 'firebase/compat/app';
export default {
    name: "app-bar",
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: Boolean,
        navbarFixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drawer: false,
            togglerActive: false,
            dropdown: [{
                    avatar: require("@/assets/img/team-2.jpg"),
                    title: '<span class="font-weight-bold">New message</span> from Laur</h6>',
                    time: "13 minutes ago",
                },
                {
                    image: require("@/assets/img/small-logos/logo-spotify.svg"),
                    title: '<span class="font-weight-bold">New album</span> by Travis Scott',
                    time: "1 day",
                },
                {
                    icon: require("@/assets/img/icons/card.svg"),
                    title: "Payment successfully completed",
                    time: "2 days",
                },
            ],
            pharmacyId: '',
            timerId: '',
            newWorkDialog: false,
            newItem: {},
            playNoticeSound: true,
            lastDoc: null,
            unsubscribeAuth: null,
            unsubscribeNotification: null,
            adminNotificationDialog: false,
            adminNotificationMessage: '',
            adminNotificationLink: '',
            adminNotificationTitle: '',
            pharmacy: {},
            unsubscribeNotice: null,
            unsubscribeQna: null,
            noticeNew: false,
            qnaNew: false,
            unsubscribePharmacy: null,
        };
    },
    async mounted() {
        let user = this.$auth.currentUser();
        this.pharmacyId = user.uid;
        try {
            this.timerId = setInterval(this.timerFunc, 5000);
            let router = this.$router;
            this.unsubscribeAuth = firebase.auth().onAuthStateChanged(function (userAuth) {
                if (userAuth == null) {
                    router.replace('/pages/auth/signin');
                }
            });

            if (this.unsubscribeNotification != null) {
                this.unsubscribeNotification();
            }
            this.unsubscribeNotification = this.$database.onNotificationsFromStartDate(this.pharmacyId, this.$database.currentTimestamp(), async (querySnapshot) => {
                let notificationPopup = true;
                let notificationSound = true;
                if (notificationPopup) {
                    for (let i = 0; i < querySnapshot.docChanges().length; i++) {
                        let notification = querySnapshot.docChanges()[i].doc.data();
                        /*
                        if (notification.type == 'newReservation') {
                            this.newReservationMessage = notification.popupMessage;
                            this.newReservationItem = await this.$database.loadHospitalWork(notification.hospitalWorkId);
                            if (notificationSound) {
                                this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/new_clinic_notice_sound.mp3?alt=media&token=56f9b16c-70b0-418d-88a8-8fa0740725fd');
                            }
                            this.newReservationDialog = true;
                        } else if (notification.type == 'waitingClinic') {
                            this.waitingClinicMessage = notification.popupMessage;
                            this.waitingClinicItem = await this.$database.loadHospitalWork(notification.hospitalWorkId);
                            if (notificationSound) {
                                this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/notice_sound.mp3?alt=media&token=1b3f2271-8f63-47f1-abae-fc975b805c49');
                            }
                            this.waitingClinicDialog = true;
                        } else if (notification.type == 'certificateRequest') {
                            this.certificateRequestMessage = notification.popupMessage;
                            if (notificationSound) {
                                this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/notice_sound.mp3?alt=media&token=1b3f2271-8f63-47f1-abae-fc975b805c49');
                            }
                            this.certificateRequestDialog = true;
                        } else if (notification.type == 'cardChanged') {
                            this.newReservationMessage = notification.popupMessage;
                            this.newReservationItem = await this.$database.loadPharmacyWork(notification.pharmacyWorkId);
                            if (notificationSound) {
                                this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/notice_sound.mp3?alt=media&token=1b3f2271-8f63-47f1-abae-fc975b805c49');
                            }
                            this.newReservationDialog = true;
                        } else*/
                        if (notification.type == 'adminNotification') {
                            this.adminNotificationTitle = notification.popupTitle;
                            this.adminNotificationMessage = notification.popupMessage;
                            this.adminNotificationLink = notification.link;
                            if (notificationSound) {
                                this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/notice_sound.mp3?alt=media&token=1b3f2271-8f63-47f1-abae-fc975b805c49');
                            }
                            this.adminNotificationDialog = true;
                            break;
                        }
                    }
                }
            });
            if (this.unsubscribePharmacy != null) {
                this.unsubscribePharmacy();
            }
            this.unsubscribePharmacy = firebase.firestore()
                .collection('pharmacys')
                .doc(this.pharmacyId)
                .onSnapshot(async (docSnapshot) => {
                    if (docSnapshot.exists) {
                        this.pharmacy = docSnapshot.data();
                        this.loadNew();
                    }
                });
            this.loadNew();
        } catch (e) {
            console.log(e);
        }
    },
    destroyed() {
        if (this.timerId != '') {
            clearInterval(this.timerId);
            this.timerId = '';
        }
        if (this.unsubscribeAuth != null) {
            this.unsubscribeAuth();
            this.unsubscribeAuth = null;
        }
        if (this.unsubscribeNotice != null) {
            this.unsubscribeNotice();
            this.unsubscribeNotice = null;
        }
        if (this.unsubscribeQna != null) {
            this.unsubscribeQna();
            this.unsubscribeQna = null;
        }
        if (this.unsubscribePharmacy != null) {
            this.unsubscribePharmacy();
            this.unsubscribePharmacy = null;
        }
    },
    methods: {
        redirectToUrl(url) {
            const currLocation = window.location;
            let currUrl = '';
            if (currLocation.port != '80') {
                currUrl = `${currLocation.protocol}//${currLocation.hostname}:${currLocation.port}${currLocation.pathname}${currLocation.search}`;
            } else {
                currUrl = `${currLocation.protocol}//${currLocation.hostname}${currLocation.pathname}${currLocation.search}`;
            }
            window.location.href = currUrl + '#' + url;
        },
        loadNew() {
            if (this.unsubscribeNotice != null) {
                this.unsubscribeNotice();
            }
            this.unsubscribeNotice = this.$database.onNewAdminNotices(this.pharmacy.readNotices, async (querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    this.noticeNew = true;
                } else {
                    this.noticeNew = false;
                }
            });
            if (this.unsubscribeQna != null) {
                this.unsubscribeQna();
            }
            if (this.$utils.isEmpty(this.pharmacy.id) == false) {
                this.unsubscribeQna = this.$database.onNewQnas(this.pharmacy.id, this.pharmacy.readQnaAnswers, async (querySnapshot) => {
                    if (querySnapshot.docs.length > 0) {
                        this.qnaNew = true;
                    } else {
                        this.qnaNew = false;
                    }
                });
            }
        },
        goLink(adminNotificationLink) {
            window.location.href = adminNotificationLink;
        },
        play(sound) {
            if (sound) {
                var audio = new Audio(sound);
                try {
                    audio.play();
                } catch (e) {
                    console.log(e);
                }
            }
        },
        updatedLastDoc(doc) {
            this.lastDoc = doc;
        },
        async timerFunc() {
            let today = new Date();
            let date = new Date(today);
            date.setMinutes(date.getMinutes() - 5);
            let newItems = await this.$database.loadNewPharmacyWorks(this.pharmacyId, this.lastDoc, this.updatedLastDoc);
            if (newItems.length > 0) {
                this.newItem = newItems[0];
                if (this.playNoticeSound) {
                    this.play('https://firebasestorage.googleapis.com/v0/b/doctor365-9a68d.appspot.com/o/new_pharmacy_work_notice_sound.mp3?alt=media&token=f7b95682-37cf-46fa-8e35-9380afabe5fe');
                }
                this.newWorkDialog = true;
            }
        },
        toggleDrawer() {
            this.togglerActive = !this.togglerActive;
            this.$emit("drawer-toggle", true);
        },
        minifyDrawer() {
            this.togglerActive = !this.togglerActive;
            this.mini = !this.mini;
            const body = document.getElementsByTagName("body")[0];

            if (body.classList.contains("drawer-mini")) {
                body.classList.remove("drawer-mini");
            } else {
                body.classList.add("drawer-mini");
            }
        },
        async logout() {
            let userStatusDatabaseRef = firebase.database().ref('/pharmacy_user_status/' + firebase.auth().currentUser.uid);
            let isOnlineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };
            await userStatusDatabaseRef.update(isOnlineForDatabase);
            await this.$auth.logout();
            this.$router.replace("/pages/auth/signin");
        },
    },
    watch: {
        toggleActive(val) {
            this.togglerActive = val;
        },
    },
};
</script>
